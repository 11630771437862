import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Form from "../components/Form";
import BackgroundImage from "gatsby-background-image";
import IconForm from "../images/icon-form.svg";
import IconOffers from "../images/icon-offers.svg";
import IconRocket from "../images/icon-rocket.svg";
import { globalMobileWidth } from "../utils/formUtils";

const TravelForm = styled.section`
  background: #fdfdfd;
  display: flex;
  .col-right {
    background: #fdfdfd;
    padding: 0 30px;
    form {
      background: #fdfdfd;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      flex-basis: 100%;
      padding: 0;
    }
  }
  @media all and (max-width: ${globalMobileWidth}px) {
    flex-wrap: wrap;
  }
  .icons-wrapper {
    display: flex;
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    justify-content: space-between;
    padding-top: 2rem;
    span {
      font-size: 14px;
      text-align: center;
    }
    img {
      height: 40px;
      margin-bottom: 3px;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      display: none;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: center top;
  background-size: 1550px;
  flex-basis: 60%;
  position: relative;
  background-repeat: repeat-y;
  background-color: #0a203a;
  &:before {
    background-size: cover !important;
    background-repeat: no-repeat;
  }
  @media all and (max-width: ${globalMobileWidth}px) {
    flex-basis: 100%;
    &:before {
      background-size: cover !important; // Required to override gatsby
    }
  }
  @media all and (min-width: 2400px) {
    &:before {
      background-size: cover !important; // Required to override gatsby
    }
  }
  .heading-blurb {
    background: rgba(0, 0, 0, 0.8);
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    padding: 30px;
    color: #fff;
    position: absolute;
    right: 0;
    top: 430px;
    @media all and (max-width: 1050px) {
      top: 400px;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      margin-top: 117px;
      padding: 20px;
      position: static;
    }
  }
  h3 {
    color: #fff;
    font-size: 2rem;
    margin: 0 0 0.5rem;
    padding: 0;
    @media all and (max-width: 1050px) {
      font-size: 36px;
    }
    @media all and (max-width: 830px) {
      font-size: 30px;
    }
    @media all and (max-width: ${globalMobileWidth}px) {
      font-size: 20px;
      margin: 0;
      text-align: center;
    }
    span {
      color: #3ac0a4;
    }
  }
  p {
    margin: 0;
    font-size: 20px;
    @media all and (max-width: ${globalMobileWidth}px) {
      display: none;
    }
  }
`;

const FormLander = () => {
  const { formImage } = useStaticQuery(graphql`
    query FormLanderQuery {
      formImage: file(relativePath: { eq: "bg-homepage-header.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const trackingId =
    (typeof window !== "undefined" &&
      window.location.pathname
        .replace(/^[/]/, "")
        .replace(/\/$/, "")
        .replaceAll(/[/]/g, "-")
        .concat("-fg")) ||
    "form-lander-fg";
  return (
    <TravelForm>
      <StyledBackgroundImage fluid={formImage.childImageSharp.fluid}>
        <div className="heading-blurb">
          <h3>
            Attention RNs: <span>Travel Nurses Needed!</span>
          </h3>
          <p>
            High Paying Positions Are Still Available. See Where You Can Help.
          </p>
        </div>
      </StyledBackgroundImage>

      <div className="col-right">
        <div className="icons-wrapper">
          <span>
            <img src={IconForm} alt="Computer screen icon" />
            <br />
            One Form
          </span>
          <span>
            <img src={IconOffers} alt="People with chat boxes icon" />
            <br />
            Multiple Offers
          </span>
          <span>
            <img src={IconRocket} alt="Rocket icon" />
            <br />
            Endless Possibilities
          </span>
        </div>
        <Form
          id="fg-form-lander"
          formId={"66b24e00117f45d688ce659e"}
          trackingId={trackingId}
          v3={true}
        />
      </div>
    </TravelForm>
  );
};

export default FormLander;
