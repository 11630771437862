import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import theme from "../ui/theme";

const Content = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 90px 20px;
  h2 {
    font-size: 36px;
    font-weight: 700;
  }
  h3 {
    font-size: 18px;
    margin: 0 0 0.5rem;
  }
  @media all and (max-width: ${theme.breakpoints.md}) {
    padding: 40px 20px;
    h2 {
      font-size: 25px;
    }
  }
`;

const WorkMeetsAdventure = () => (
  <Content>
    <h2>Where work meets adventure</h2>
    <div>
      <h3>Who can become a travel nurse?</h3>
      <p>
        Travel nurses typically need 12-18 months of hospital-based RN
        experience in their field. (Though this may increase depending on your
        specialty or the requirements of the facility.) Travel nursing jobs are
        available in a variety of specialties, but there are some like ER and
        Labor and Delivery that are more in-demand than others.{" "}
        <Link to="/nursing-specialties/">
          Learn more about high-demand specialties.
        </Link>
      </p>
      <h3>What do travel nurses do?</h3>
      <p>
        Hospitals hire travel nurses to fill staffing shortages, which can be
        caused by seasonal population increases, expected leave of absences, or
        a lack of qualified nurses in their location. These facilities work with
        recruitment agencies to find skilled nurses to fill short-term
        assignments in order to provide excellent care to patients.
      </p>
      <h3>Where can travel nurses go?</h3>
      <p>
        Travel nursing jobs are available across the country. You decide where
        you want to go — if your dream location isn’t on the list, chances are
        it could be soon! Keep in mind you’ll need state RN licenses to work as
        a travel nurse. Ask your recruiter about{" "}
        <Link to="/nursing-compact-states-what-you-need-to-know/">
          compact RN licenses
        </Link>
        , which allow you to work in many states, not just one.
      </p>
      <h3>How long are assignments?</h3>
      <p>
        Most travel nursing assignments are between 8 and 26 weeks, though 13
        weeks is the standard. There is often the opportunity to renew your
        contract with the facility, too. Because assignments are short term, you
        can change locations as often as you’d like or try out different
        hospitals if you’d like to stay in your location long term.
      </p>
      <h3>How does travel nurse pay work?</h3>
      <p>
        Because of the high demand for nurses,{" "}
        <Link to="/salary-and-benefits/">
          travel nursing often pays very well
        </Link>
        . Your specific compensation will depend on your specialty, the location
        of the facility, and the contract. Travel nurse companies often provide
        other benefits like 401K, medical and dental benefits, bonus programs as
        well as{" "}
        <Link to="/housing-for-the-traveling-nurse/">stipends for housing</Link>
        , meals, and even travel expenses.
      </p>
      <h3>How do I get started?</h3>
      <p>
        We work with the{" "}
        <Link to="/travel-nursing-companies/">
          top travel nursing companies
        </Link>{" "}
        in the country. By filling out a short form, we’ll connect you with up
        to four agencies whose recruiters will work hard to connect you with the
        best travel nurse opportunities available.
      </p>
    </div>
  </Content>
);

export default WorkMeetsAdventure;
